<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="management-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>所属部门:</span>
        <a-cascader
          class="common-screen-input"
          :fieldNames="{
            label: 'name',
            value: 'id',
            children: 'child'
          }"
          :allowClear="false"
          v-model="params.departId"
          :options="selectDepart"
          placeholder="请选择"
          change-on-select
          @change="commonFun"
        />
        <!-- <el-cascader
          @change="commonFun"
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
          class="common-screen-input"
          v-model="params.departId"
          :options="selectDepart"
          clearable
        ></el-cascader> -->
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => getTableData()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => clear()" plain>重 置</el-button>
    </div>
    <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="addEdit(false)"
      >添加班次</el-button
    >
    <el-table class="common-table" :data="tableData">
      <el-table-column prop="frequencyName" label="班次名称" :formatter="tableColumn"></el-table-column>
      <el-table-column prop="type" label="班次类型" :formatter="tableColumn"></el-table-column>
      <el-table-column prop="startTime" label="班次时间" :formatter="tableColumn"></el-table-column>
      <el-table-column prop="companyName" label="所属公司" :formatter="tableColumn"></el-table-column>
      <el-table-column prop="departName" label="所属部门" :formatter="tableColumn"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" :formatter="tableColumn"></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => addEdit(scope.row)"
            >编辑</el-button
          >
          &nbsp;&nbsp;&nbsp;
          <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="
              () => {
                deleteItem(scope.row.id);
              }
            "
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      :title="!tableRow ? '添加班次' : '修改班次'"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
      :before-close="handleClose"
    >
      <div>
        <el-form
          :model="saveParams"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="班次名称" prop="frequencyName">
            <el-input
              class="dialog-input"
              v-model="saveParams.frequencyName"
              maxLength="50"
              placeholder="请填写班次名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="班次类型" prop="type">
            <el-select class="dialog-input" v-model="saveParams.type" placeholder="班次类型" >
              <el-option label="正常" value="正常" > </el-option>
              <!-- <el-option label="休息" value="休息" > </el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="所属公司" prop="companyId">
            <el-select
              class="dialog-input"
              v-model="saveParams.companyId"
              placeholder="请选择"
              @change="saveCompanyFun"
              filterable
            >
              <el-option
                v-for="item in rolesCompany"
                :key="item.id + ''"
                :label="item.name"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门" prop="departId">
            <a-cascader
              class="dialog-input"
              :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'child'
              }"
              :allowClear="false"
              v-model="saveParams.departId"
              :options="selectDepart"
              placeholder="请选择"
              change-on-select
            />
          </el-form-item>
          <!--          <el-form-item prop="name">-->
          <!--            <el-checkbox v-model="ruleForm.date">跨天排班</el-checkbox>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <template slot="label">
              <span class="common-form-BT">
                班次时间
              </span>
            </template>
            <div style="display: inline-block">
              <el-form-item label="" prop="startTime">
                <el-time-picker
                  class="common-picker-time"
                  v-model="saveParams.startTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  placeholder="任意时间点"
                >
                </el-time-picker>
              </el-form-item>
            </div>

            -
            <div style="display: inline-block">
              <el-form-item label="" prop="endTime">
                <el-time-picker
                  class="common-picker-time"
                  v-model="saveParams.endTime"
                  value-format="HH:mm"
                  format="HH:mm"
                  placeholder="任意时间点"
                >
                </el-time-picker>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="() => { handleClose() }"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleForm');
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  frequencyList,
  frequencySave,
  frequencyUpdate,
  frequencyDelete,
} from "../../service/service.js";
import { commonAllRole, commonDepartAll } from "../../service/common.js";
import { Config, tableColumn } from "@/utils";
import { mapState } from "vuex";
import { Cascader } from "ant-design-vue";

export default {
  components: {
    Breadcrumb,
    aCascader: Cascader
  },
  data() {
    return {
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "班次管理", isLink: false },
      ],
      loading: false,
      tableRow: "", // 客户id用于编辑/查看
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      indexKey: 1,
      centerDialogVisible: false, // 修改密码员工弹窗
      rules: {
        frequencyName: [{ required: true, message: "请填写班次名称", trigger: "change" }],
        companyId: [{ required: true, message: "请填选择所属公司", trigger: "change" }],
        startTime: [{ required: true, message: "请选择班次时间", trigger: "change" }],
        endTime: [{ required: true, message: "请选择班次时间", trigger: "change" }],
        type: [{ required: true, message: "请选择班次类型", trigger: "change" }],
      },
      roleSelect: "",
      params: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        companyId: "",
        // roleId: null,
        departId: [],
      },
      saveParams: {
        startTime: "",
        endTime: "",
        frequencyName: "",
        companyId: "",
        departId: [],
        type: "",
      },
      fromName: "",
      selectRole: [], // 角色下拉
      selectDepart: [], // 部门下拉
      saveSelectDepart: [], // 新增保存部门下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item)=>{return item.id != 0}), // 所属公司下拉
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
    };
  },
  created() {
    // 获取comID&&请求列表
    this.getComId()

    this.commonAllRole();
    // this.getTableData();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getTableData();
        this.commonDepartAll(this.params.companyId)
      },400)
    },
    commonFun() {
      this.params.pageNum = 1
      this.params.pageSize = 10
      this.getTableData()
    },
    async addEdit(row) {
      if (!row) {
        this.saveParams = {
          startTime: "",
          endTime: "",
          frequencyName: "",
          companyId: "",
          departId: [],
          type: "",
        };
        this.fromName = "save";
        this.tableRow = "";
        if (this.$refs["ruleForm"]) {
          this.$refs["ruleForm"].resetFields();
        }
      } else {
        this.saveCommonDepartAll(row.companyId);
        this.tableRow = row;
        this.fromName = "edit";
        // 修改班次管理
        this.saveParams = {
          frequencyName: row.frequencyName || "",
          startTime: row.startTime ? row.startTime.split('-')[0] : "",
          endTime: row.endTime || "",
          id: row.id,
          companyId: row.companyId,
          // departId: row.departId,
          type: row.type
        };
        if(row&&row.departId){
          this.findParent(row.departId,this.selectDepart);//部门
        }
        // this.saveParams.departId = row.departId; //row.departId
      }
      this.centerDialogVisible = true;
      // this.indexKey++;
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      let params = { ...this.params };
      if (params.companyId == null) {
        params.companyId = "";
      } else if (!params.departId.length) {
        params.departId = ""
      }else if(params.departId.length){
        params.departId = params.departId[params.departId.length - 1]
      }
      
      let resData = (await frequencyList(params)).data;
      resData.list.forEach((item) => {
        item.startTime = item.startTime + "-" + item.endTime;
      });

      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
    submitForm(formName) {
      // 新增/编辑
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let saveParams = {...this.saveParams}
          if (!saveParams.departId || !saveParams.departId.length) {
            saveParams.departId = ""
          }else if(saveParams.departId.length){
            saveParams.departId = saveParams.departId[saveParams.departId.length - 1]
          }
          if ("save" == this.fromName) {
            await frequencySave(saveParams);
          } else if ("edit" == this.fromName) {
            await frequencyUpdate(saveParams);
          }
          this.$message.success("提交成功");
          this.$refs[formName].resetFields();
          this.centerDialogVisible = false;
          this.getTableData();
        } else {
          return false;
        }
      });
    },
    companyFun(id) {
      //选择公司筛选
      this.params.companyId = id;
      this.getTableData()
      this.commonDepartAll(id);
    },
    cascaderFun() {
      // 选择部门
      let params = { ...this.params };
      if (!params.companyId) {
        this.$message.error("请先选择所属公司");
      }
    },
    saveCompanyFun(id) {
      //选择公司筛选
      this.saveCommonDepartAll(id);
    },
    saveCascaderFun() {
      // 选择部门
      let saveParams = { ...this.saveParams };
      if (!saveParams.companyId) {
        this.$message.error("请先选择所属公司");
      }
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    async saveCommonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.saveSelectDepart = resData ? resData : [];
    },
    async commonAllRole() {
      // 角色下拉
      let resData = (await commonAllRole()).data;
      this.selectRole = resData;
    },
    async deleteItem(id) {
      await frequencyDelete(id);
      this.$message.success("删除成功");
      this.centerDialogVisible = false;
      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getTableData();
    },
    clear() {
      // 重置
      // this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
      this.commonDepartAll(this.comId)
      this.params.departId = "";
      this.getTableData();
    },
    handleClose(){
      this.saveParams = {
        startTime: "",
        endTime: "",
        frequencyName: "",
        companyId: "",
        departId: [],
        type: "",
      },
      this.centerDialogVisible = false;
    },
    // 递归父id
    findParent(childrenId, arr, path) {
      if (path === undefined) {
        path = []
      }
      for (let i = 0; i < arr.length; i++) {
        let tmpPath = path.concat();
        tmpPath.push(arr[i].id);
        if (childrenId == arr[i].id) {
          return this.saveParams.departId = tmpPath
        }
        if (arr[i].child) {
          let findResult = this.findParent(childrenId, arr[i].child, tmpPath);
          if (findResult) {
            return this.saveParams.departId = findResult
          }
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.management-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
<style lang="less">
.ant-cascader-menus{
  z-index: 9999 !important;
}
</style>
